@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {

  h1 {
    @apply text-3xl;
  }

  h2 {
    @apply text-2xl;
  }
  
  h3 {
    @apply text-xl;
  }

}